import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
// Screens
import Landing from "./screens/Landing.js";

import "./assets/css/slick.css"
import "./assets/css/social-icons.css"
import "./assets/css/style.css"
import Header from "./components/Header.js";
import Footer from "./components/Footer.js";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DED from "./screens/DED.js";
import Amer from "./screens/Amer.js";
import EHS from "./screens/EHS.js";
import EmiratesID from "./screens/EmiratesID.js";

export default function App() {
	
  return (
    <>
      <Helmet>
   

	<script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js" integrity="sha384-Tc5IQib027qvyjSMfHjOMaLkfuWVxZxUPnCJA7l2mCWNIpG9mGCD8wGNIcPD7Txa" crossorigin="anonymous"></script>
	
	<script src="js/custom.js"></script>   </Helmet>
	
	<Header />
		<Routes>
			<Route path="/" element={<Landing/>}></Route>
			<Route path="/Home" element={<Landing/>}></Route>
			<Route path="/Amer" element={<Amer/>}/>
			<Route path="/DED" element={<DED/>}/>
			<Route path="/EHS" element={<EHS/>}/>
			<Route path="/why" element={<Landing/>}/>
			<Route path="/Contact" element={<Landing/>}/>
			<Route path="/EmiratesID" element={<EmiratesID/>}/>
			
		</Routes>
	
	  <Footer/>
    </>
  );
}

