import React from 'react'
import Vid from '../assets/img/Quickstop.mp4'
function Portfolio() {
  return (
    <>
        <section id="portfolio">


<div class="video-section">

    <div class="container " style={{marginLeft:'0px',marginRight:'0px',width:"100%", height:"100%"}}>

        <div class="row" style={{width :"inherit "}}>

            <div class="col-md-7 ">
            <video className=' videobox' width="100%" height="100%" muted loop autoPlay>
                <source src={Vid} type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
               

        

            </div>

            <div class="col-md-5 " style={{marginTop:'30px'}}>
                
                <h4>About Quick stop</h4>

                <p><i> Simplifying Services for Individuals and Businesses in Dubai</i></p>

                <div class="video-description" style={{color: 'black'}}>

                    <p>Amer Quick Stop is a government-approved Amer center and one of Dubai’s main government service providers. AMER services in Dubai are well-versed in the ins and outs of UAE legislation on business incorporation, having partnered with government officials and agencies in the United Arab Emirates.</p>

                    

                    <p>. We provide a one-stop-shop for business setup services, trade licenses, and visa needs. We will assist you with paperwork to company formation to all government services under one roof, and we make it quick! </p>

                </div>

                <a href="tel:+971-55-106-9226" class="btn btn-default white-blue">Call Us Now</a>

            </div>

        </div> 

    </div> 

</div> 
{/* 
<div class="business-section">

    <div class="container">

        <div class="row">

            <h3>Molly is great for any busioness</h3>

            <p><i>Yes Mentum is fully responsive,and look’s great on any device.</i></p>

            <a href="#" class="btn btn-default white-yellow">Order Now</a>

        </div> 

        <div class="row">

            <div class="col-md-10 col-md-offset-1">

                <div class="img-container">

                    <img src="img/svg/browsers.svg" alt="Browsers image"/>

                </div>

            </div>

        </div> 

    </div>

</div> */}

</section>

    
    </>
  )
}

export default Portfolio