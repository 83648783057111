import React, { useEffect } from 'react'
import Home from '../components/Home'
import NewID from '../assets/img/visa/Emirates/new.svg'
import Renew from '../assets/img/svg/services/emirateID.png'
import Info from '../assets/img/visa/Emirates/pdata.png'

import Corporate from '../assets/img/visa/Emirates/ohnp_66eb_230222.jpg'
var Scroll = require('react-scroll');
var scroll = Scroll.animateScroll;
function EmiratesID() {
    useEffect(() => {
        scroll.scrollToTop();


    }, [])

  return (
    <>
        <section id=' emiratesID'>
            <Home page={'Emirates'}/>
            <div className='amer-box' >
                <div className='newID-sub-box-one sub-box-one'   >
                   
                        <img src={NewID}  alt="New Emirates ID Application" style={{maxHeight:'500px', borderRadius:'0px'}} />
                    
                    
                </div>
                <div className=' newID-sub-box-two  sub-box-two '  style={{backgroundColor:'#D6F7E7'}} >
                    <h3>New Emirates ID Application</h3>
                    <p>We assist individuals in applying for a new Emirates ID card. Our team guides applicants through the application process, ensuring all necessary documents are submitted correctly. We handle the submission of applications to the relevant authorities, making the process smooth and efficient.</p>
                </div>
                
            </div>


            <div className='amer-box' >
                
                <div className=' sub-box-two'  style={{backgroundColor:'#ffcccc'}} >
                    <h3>Emirates ID Renewal</h3>
                    <p>We provide convenient renewal services for expired or expiring Emirates ID cards. Our team assists individuals in preparing the required documents, submitting the renewal application, and collecting the renewed Emirates ID card once it is ready.</p>
                </div>
                <div className=' sub-box-one' >
                    <div className=' extra-box'>            
                    <img src={Renew}  alt="Emirates ID Renewal" style={{maxHeight:'190px', borderRadius:'0px'}} />
                    </div>
                </div>
            </div>


            <div className='amer-box emirate-green' style={{padding:'0px'}}>
                <div className=' sub-box-one '   >
                    <div className=' extra-box'>
                    <img src={Info}  alt="Update Personal Information" style={{maxHeight:'450px', borderRadius:'0px'}} />    
                    </div>
                    
                </div>
                <div className=' sub-box-two' style={{backgroundColor:'#D6F7E7'}}>
                    <h3>  Update Personal Information</h3>
                    <p>We facilitate the process of updating personal information on Emirates ID cards. Whether it is a change in name, picture, or other details, our team ensures a seamless update process, keeping your Emirates ID card information accurate and up to date.</p>
                </div>
                
            </div>

{/*             
            <div className='amer-box' style={{marginTop:'3%',marginBottom:'3%'}}>
                
                <div className=' sub-box-two '  style={{backgroundColor:'#D6F7E7'}} >
                    <h3>Family Application Services</h3>
                    <p>We provide family application services, enabling individuals to apply for Emirates ID cards for their family members. Our team assists in preparing the required documents and submitting the applications for multiple family members, streamlining the process.</p>
                </div>
                <div className=' sub-box-one emi-sub-one'   >

                      <div className=' extra-box'>
                    <img src={NewID}  alt="Medical Image" style={{maxHeight:'170px', borderRadius:'0px'}} />    
                    </div>
                </div>
            </div> */}



            <div className='amer-box' >
                
            <div className=' sub-box-one '   >
                
                   <img src={Corporate}  alt="Corporate Services" style={{maxHeight:'370px',padding:'20px', borderRadius:'0px'}} />    
                    
                </div>
                <div className=' sub-box-two'  style={{backgroundColor:'#ffcccc'}} >
                    <h3>Corporate Services</h3>
                    <p>We offer specialized services for corporations and organizations in Dubai. This includes assisting with Emirates ID applications for employees, managing bulk renewals, and providing comprehensive support for compliance with Emirates ID requirements.</p>
                </div>
            </div>

            
        </section>


    </>
  )
}

export default EmiratesID