import React, { useRef, useState } from 'react'
import emailjs from "@emailjs/browser"
import Swal from 'sweetalert2';


function Contactus() {
    
    const form= useRef();
    const [email,setEmail]=useState('')
    const sendMail=(e)=>{
        e.preventDefault();
       
          const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    
          // Check if the email is valid
          if (emailPattern.test(email)) {

            Swal.fire(
                'Message sent successfully',
                'Our customer representative will contact you soon',
                'success'
              )  
            emailjs.sendForm(process.env.REACT_APP_MAIL_SERVICE_ID,process.env.REACT_APP_MAIL_TEMPLATE_ID,form.current,process.env.REACT_APP_MAIL_PUBLIC_KEY).then((res)=>{
                if(res.text=='OK')
                {
                    
                   
                }
                else{
    
                }
            })
          } else {
            Swal.fire(
                'Kindly Fill up All the fields',
                '',
                'error'
              )  
          
      
    }
    }
  return (
    <>
    	<section id="contact">

<div class="container">

    <div class="row">
        
        <div class="col-md-8 col-md-offset-2">

            <div class="title-content">
                
                <h2 class="section-title">Contact us</h2>

                <p>We are here to answer all of your queries</p>

            </div>

        </div>

    </div> 
    
    <form ref={form}  class="contact-form" onSubmit={sendMail} >
        
        <div class="row">

            <div class="col-md-4">
                
                <label class="sr-only" for="Name">Name</label>
                <input type="text" name="customerName" id="Name" placeholder="Your Name"/>

            </div>
            
            <div class="col-md-4">
                
                <label class="sr-only" for="Email">Email</label>
                <input type="email" value={email} onChange={(event) => setEmail(event.target.value)} name="customerEmail" id="Email" placeholder="Your Mail"/>

            </div>

            <div class="col-md-4">
                
                <label class="sr-only" for="Subject">Subject</label>
                <input type="text" name="customerSubject" id="Subject" placeholder="Subject"/>

            </div>

        </div> 

        <div class="row">

            <div class="col-md-12">
                
                <label class="sr-only" for="Message">Message</label>
                <textarea name="message" id="Message" rows="9" placeholder="Enter Message Here..."></textarea>

            </div>
            
        </div> 

        <button type="submit" class="btn btn-default white-blue">Send Message</button>

    </form>	

</div> 

</section>



    </>
  )
}

export default Contactus
