import React, { useState } from 'react'
import Amer from '../assets/img/svg/services/amerlogo.svg'
import DED from '../assets/img/svg/services/DED_new.png'
import ID from '../assets/img/svg/services/emirates-identity-authority-seeklogo.com.svg'

import Medical from '../assets/img/svg/services/ministry-of-health-prevention-seeklogo.com.svg'

import Landing2 from '../assets/img/landing2.png'



import {  useNavigate } from 'react-router-dom'
function Services() {
    const Navigate=useNavigate();
  const changeService=(service)=>{
      Navigate(service);
  }
    return (
        <>
            <section id="services">

                <div class="container" style={{width:'100%'}}>

                    <div class="row">

                        <div class="col-md-8 col-md-offset-2">

                            <div class="title-content">

                                <h2 class="section-title">Services</h2>

                                <p style={{fontSize:'larger'}}>Whether you need assistance with Amer services, Dubai economy-related processes, Emirates ID applications, or typing services for medical test applications, we are here to help.We are committed to delivering efficient and convenient solutions for obtaining and managing individual and business needs.</p>

                            </div>

                        </div>

                    </div>
                    
                           <div class="services-container">


                                    <div class="row"  >

                                        <div  onClick={()=>changeService('/Amer')} class=" col-md-3 col-sm-6" >

                                            <div class="service" >

                                                <div >

                                                    <img src={Amer} alt="" height={'150px'}/>
                                                    <figcaption ><h4 class="service-title">Amer Services</h4></figcaption>
                                                </div>

                                                

                                            </div>

                                        </div>

                                        <div class="col-md-3 col-sm-6"  onClick={()=>changeService("/DED")}>

                                            <div class="service">

                                                <div>
                                                <img src={DED} alt="Dubai Economy & Tourism" height={'110px'} style={{marginTop:'10%'}}/>
                                                    <figcaption style={{marginTop:'2%'}}><h4 class="service-title">Dubai Economy Department</h4></figcaption>
                                                </div>

                                               

                                            </div>

                                        </div>

                                        <div class="col-md-3 col-sm-6" onClick={()=>changeService("/EHS")}>

                                            <div class="service">

                                                <div>
                                                <img src={Medical} style={{transform:'scale(1.8)'}} alt="Medical ID typing" height={'145px'}  />
                                                    <figcaption ><h4 class="service-title" >Medical Typing</h4></figcaption>
                                                </div>

                                               

                                            </div>

                                        </div>

                                        <div class="col-md-3 col-sm-6" onClick={()=>changeService("/EmiratesID")}>

                                            <div class="service">

                                                <figure>
                                                <img src={ID} alt="Emirates ID" height={'165px'}  />
                                                    <figcaption><h4 class="service-title">Emirate ID</h4></figcaption>
                                                </figure>

                                              

                                            </div>

                                        </div>

                                    </div>

                                </div>
                               
                    

                </div>

                <div class="we-make-section " >

                    <div class="overlay greenshade"></div>

                    <div class="container">

                        <div class="row reorder-sm">

                            <div class="col-md-5">

                                <div class="whyUs" >
                                    <img src={Landing2} height={"360px"}/>
                                </div>

                            </div>

                            <div class="col-md-7">

                                <div class="features-container">

                                    <h3>What makes us different? </h3>

                                    <p>Quik Stop Amer Center provides document delivery and pickup service at your doorstep.</p>

                                    <ul class="features-list">
                                        <li>Document pickup and dropoff service</li>
                                        <li>Years Of experience</li>
                                        <li>palenty of parking available</li>
                                        <li>well trained staff</li>
                                    </ul>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>


            </section>

        </>
    )
}

export default Services