import React from 'react'
import AmerLogo from '../assets/img/svg/services/amerlogo.svg'
 import Quickstop from '../assets/img/quickStopLogo.png'
 import DED from "..//assets/img/svg/services/DED_home.png"
 import EHS from "../assets/img/svg/services/ministry-of-health-prevention-seeklogo.com.svg"
 import EmirateID from "../assets/img/svg/services/emirates-identity-authority-seeklogo.com.svg"
 import Phone from "../assets/img/phone.gif";
 
function Home(props) {
   
    const data={
        "home":{
            "image":Quickstop,
            "color":"overlay redshade",
            "heading":"Welcome to Quick Stop",
            "para":"Welcome to the Quick Stop, your reliable destination for a range of services related to Amer services, Dubai economy, Emirates ID, and ID medical test application typing. We are dedicated to providing efficient and convenient solutions for individuals and businesses in Dubai. Our experienced team is committed to delivering high-quality services while ensuring customer satisfaction and meeting the required standards and regulations.  "
        },
        "Amer":{
            "image":AmerLogo,
            "color":"overlay greenshade",
            "heading":'Amer Services',
            "para":"Our platform provides a user-friendly and efficient way to access a wide range of essential Amer services, saving time and effort for our users. Whether you're a resident, expatriate, or business owner in Dubai, Amer Services is your one-stop solution for hassle-free government transactions."
        },
        "DED":{
            "image":DED,
            "color":"overlay whiteshade",
            "heading":'Dubai Economy Department',
            "para":"Welcome to Dubai Economy Department Services, your trusted partner in driving economic growth and providing essential services for businesses and entrepreneurs in Dubai. As the leading government organization dedicated to supporting the business community, we offer a wide range of services to facilitate business setup, promote trade, and ensure regulatory compliance. Our goal is to create an enabling environment for businesses to thrive and contribute to Dubai's vibrant economy."
        },
        "EHS":{
            image:EHS,
            "color":"overlay EHSshade",
            "heading":'Medical Test Application For Emirates ID ',
            "para":"we provide streamlined typing services for ID medical fitness test applications. We specialize in assisting individuals and healthcare professionals with accurately typing and preparing various medical fitness test application documents."
        
        },
        "Emirates":{
            image:EmirateID,
            "color":"overlay Emirate-shade",
            "heading":'Emirates ID Services',
            "para":"We are premier provider of Emirates ID-related services in Dubai. We are committed to delivering efficient and convenient solutions for obtaining and managing Emirates ID cards. Our dedicated team of professionals ensures a seamless experience for residents and citizens of Dubai. With our comprehensive range of services, we strive to meet the needs of individuals, families, and organizations in acquiring and renewing Emirates ID cards."
        
        }

    }
   
  return (
    <>
    <section id="home">
    <a href="https://api.whatsapp.com/send?phone=+971551069226&text=Hello,Can I get some information?" class="float" target="_blank">
<i class="fa fa-whatsapp my-float"></i>
</a>
<a href="tel:+971-55-106-9226" class="float-call-btn" target="_blank">
<img src={Phone} class=" my-float"/>
</a>  
    <h1 class="sr-only">Home</h1>
    <div id="firstBox " class=" slide "  data-ride="carousel">
    

<div class="we-make-section">

    <div class={data[props.page].color}></div>
    
    <div class="container" >
        
        <div class="row reorder-sm" >
            
            <div class="col-md-6" style={{marginTop:props.page=='home'?'10%':'20%'}}>

                <div class="features-container ">

                    <h3 class="down-text-motion">{data[props.page].heading}</h3>

                    <p class="down-text-motion">{data[props.page].para}</p>

                   

                </div> 
                
            </div> 

            <div class="col-md-6">

                <div class="img-container">
                    <img src={data[props.page].image} alt={data[props.page].heading} style={{maxHeight:props.page=='EHS'||props.page=='home'?"400px" :"600px",marginTop:props.page=='Emirates'||props.page=='DED'?'100px':'0px' }}/>
                </div>
            
            </div> 

        </div> 

    </div> 

</div> 

      </div> 
</section>
</>
  )
}

export default Home