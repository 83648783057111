import React, { useEffect, useState } from 'react'
import QuickStop from '../assets/img/quickStopLogo.png'
import { useNavigate,Link } from 'react-router-dom';
var Scroll = require('react-scroll');
var scroll=Scroll.animateScroll
var scroller = Scroll.scroller;
function Header() {
	const [isOpen,setIsOpen]=useState(false)
	const handleScroll = (target) => {
		if (target=='home')
		{
			scroll.scrollToTop()
			  return
		}
		if (window.location.href.includes('Home'))
		{
			
			return
		}
	     scroller.scrollTo(target, {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: 50,
          })
	  };
	  const navigate=useNavigate()
	  useEffect(() => {
			if(window.innerWidth<720){
				setIsOpen(true)
				
			}	
	  
		
	  }, [])
	  
	  const move=(to)=>{
		navigate(to)
	  }
  return (
    <header id="header">
		
		
		<h1 class="sr-only">Molly | Home page</h1>
		
		<nav id="main-navigation" class="navbar navbar-default navbar-fixed-top">
		  
		  <h1 class="sr-only">Main navigation</h1>

		  <div class="container">
		    
		    <div class="navbar-header">
		      <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#main-navbar" aria-expanded="false">
		        <span class="icon-bar" ></span>
		        <span class="icon-bar"></span>
		        <span class="icon-bar"></span>
		        <span class="sr-only">Toggle navigation</span>
		      </button>
		      <Link id="brand-mobile" class="navbar-brand navbar-brand-center smoothScroll" onClick={()=>handleScroll('home')} to="/Home">
		        <img alt="Molly" src={QuickStop}/>
		      </Link>
		    </div>

		    
		    <div class= "collapse navbar-collapse" id="main-navbar">
			  <ul class="nav navbar-nav me-auto">
		        <li   ><Link data-toggle={isOpen?'collapse':""} data-target={isOpen?'#main-navbar':""} aria-expanded={isOpen?'false':""}  to="/Home" onClick={()=>handleScroll('home')} > Home </Link></li>
		        <li><Link data-toggle={isOpen?'collapse':""} data-target={isOpen?'#main-navbar':""} aria-expanded={isOpen?'false':""}   to="Amer"    onClick={()=>move('/Amer')} >Amer</Link></li>
				<li><Link data-toggle={isOpen?'collapse':""} data-target={isOpen?'#main-navbar':""} aria-expanded={isOpen?'false':""}  to="DED"   href='#' onClick={()=>move('/DED')} >Dubai Economy & Tourism</Link></li>
		      	
		      	<li id="brand-desktop">
					
					<Link class="navbar-brand "  to='/Home' >
						<img alt="Molly" src={QuickStop}/>
					</Link>
		      	</li>
				  <li><Link  data-toggle={isOpen?'collapse':""} data-target={isOpen?'#main-navbar':""} aria-expanded={isOpen?'false':""} to='/EmiratesID'  href="#">Emirates ID</Link></li>
		     
				  <li><Link data-toggle={isOpen?'collapse':""} data-target={isOpen?'#main-navbar':""} aria-expanded={isOpen?'false':""}  to='EHS'  href="#">Medical</Link></li>
		        
		      
		        <li><Link data-toggle={isOpen?'collapse':""} data-target={isOpen?'#main-navbar':""} aria-expanded={isOpen?'false':""} to='/Contact'onClick={()=>handleScroll('contact')}>Contact</Link></li>
		      </ul>
		    </div>
		  </div>
		</nav>

	</header>

  )
}

export default Header