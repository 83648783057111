import "react-responsive-carousel/lib/styles/carousel.min.css";

import Divya from '../assets/img/Review/divia_rev.png'
import Fatima from '../assets/img/Review/FatimaReview.png'
import Mefa from '../assets/img/Review/ghareeb.png'
import Basheer from '../assets/img/Review/basheer.png'


function ReviewCarousal() {
    
  return (
    <>
    <section id='reviews' >
     <div className=' container' style={{marginTop:'50px'}}>

    <div class="row">
        
        <div class="col-md-8 col-md-offset-2">

            <div class="title-content">
                
                <h3 class="section-title">See what people think about us</h3>

              

            </div>

        </div>

    </div>
    <div class="services-container">


<div class="row">
    
    <div class="col-md-3 col-sm-6">

        <div class="reviewcard">
            
            <div >
                
                 <img src={Divya}  style={{ height:'70px'}} />
                <figcaption><h4 class="service-title">Divya Premchand</h4></figcaption>
                
            </div>
            <div className=' container-fluid starbox'>
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
            </div>
            <p>One of the best Amer center in Dubai, helpful and professional Staff. thanks again for your good service.</p>

        </div>

    </div>

    <div class="col-md-3 col-sm-6">

        <div class="reviewcard">
            
            <figure>
            <img src={Fatima}  style={{ height:'70px'}} />
                <figcaption><h4 class="service-title">Ali Opal</h4></figcaption>
            </figure>
            <div className=' container-fluid starbox'>
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
            </div>
            <p>Its a place which gave me one stop solution once i have applied for my family visas. Nice and cooperative staff. There guidance has make hassles free. </p>

        </div>

    </div>

    <div class="col-md-3 col-sm-6">

        <div class="reviewcard">
            
            <figure>
            <img src={Mefa}  style={{ height:'70px'}} />
                <figcaption><h4 class="service-title">Ghareeb</h4></figcaption>
            </figure>
            <div className=' container-fluid starbox'>
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
            </div>
            <p>ما شاء الله أمانة وثقة وأخلاق وسرعة انجاز في المعاملات بالتوفيق</p>

        </div>

    </div>

    <div class="col-md-3 col-sm-6">

        <div class="reviewcard">
            
            <figure>
            <img src={Basheer}  style={{ height:'70px'}} />
                <figcaption><h4 class="service-title">Basheer Jasim</h4></figcaption>
            </figure>
            <div className=' container-fluid starbox'>
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
            </div>
            <p>Really it's good arrange, clean and comfort facilities and helpfully staff with friendly treatment specially Mr Ahmed Jawid.</p>

        </div>

    </div>

</div> 

</div> 

</div> 
<div class="services-cta-container">

    <a href="https://www.google.com/search?q=Amer+%26+Dubai+Economy+-+Quick+Stop+Gov+Tr+Center+-+Al+Maktoum+Hospital+Road+-+Dubai&oq=amer&aqs=chrome.0.69i59l3j46i433i512j46i199i433i465i512j0i20i263i512j69i60l2.1409j0j7&sourceid=chrome&ie=UTF-8#ip=1&lrd=0x3e5f43d5360c680b:0xbae330e4be09e182,1,,,," class="btn btn-default white-yellow">See All</a>

</div>
</section>

    </>
  );
}

export default ReviewCarousal;