import React, { useEffect, useState } from 'react'
import Visa from '../assets/img/visa/Amer/qpmp_ive4_211101.jpg'
import Status from '../assets/img/visa/Amer/19721.jpg'
import Establishment from '../assets/img/visa/Amer/esteblishment.png'
import Fine from '../assets/img/visa/Amer/fine.jpg'
import Golden from '../assets/img/visa/Amer/investor.png'
import Family from '../assets/img/visa/Amer/family.png'
import Job from '../assets/img/visa/Amer/businessman.png'
import Cancel from '../assets/img/visa/Amer/cancelled.png'


import Home from '../components/Home'

var Scroll = require('react-scroll');
var scroll = Scroll.animateScroll;


function Amer() {
    
    // const store={
    //     "family":{
    //         "Entry":["Family Passport Copy","Photo","Sponsor Passport","Sponsor Visa Copy","Sponsor Original ID","Sponsor Labour Contract If Employee"],
    //         "ChangeStatus":["New Visa","Old Visa (If have)","Sponsor Emirates ID(Copy)"],
    //         "Medical":["Passport","Visa","Photo"],
    //         "Emirates ID":["Original Passport","Visa"],
    //         "Insurance":["Passport","visa","Sponsor Passport","Sponsor Visa","Emirates ID"],
    //         "VisaStamping":["Dependent Visa Copy","Dependent Passport","Dependent Emirates ID Form","Medical","Insurance Certificate","Company Trade License Copy","Sponsor Emirates ID"]
    //     },
    //     "work":{
    //         "Entry":["Employee Passport","Photo","Sponsor Original ID"],
    //         "ChangeStatus":["New Visa","Old Visa (If have)","Sponsor Emirates ID(Copy)"],
    //         "Medical":["Passport","Visa","Photo"],
    //         "Emirates ID":["Original Passport","Visa"],
    //         "Insurance":["Passport","visa","Trade License","Emirates ID","Labour Contract"],
    //         "VisaStamping":["Visa Copy","Passport","Emirates ID Employee","Medical","Insurance Certificate","Company Trade License Copy","Sponsor Emirates ID"]
    //     },
    // }


    const store={
        "family":{
            "Heading":'Family Visa Requirements',
            "subHeadingOne":"",
           "dataOne": ["Family Passport Copy","Photo","Sponsor Passport","Sponsor Visa Copy","Sponsor Original ID","Sponsor Labour Contract If Employee"]},
           "subHeadingTwo":"",
            "dataTwo":[],
            "subHeadingThree":"",
            "dataThree":[],
            "subHeadingFour":"",
            "dataFour":[],
            "talentHeading":"",
            "talentSubHeadingOne":"",
            "talentDataOne":[],
            "talentSubHeadingTwo":"",
            "talentDataOneTwo":[],
         
            "subHeadingFive":"",
            "dataFive":"",
            "subHeadingSix":"",
            "sixSubHeadingOne":"",
            "dataSixOne":[],
            "sixSubHeadingTwo":"",
            "dataSixTwo":[],
            
            
        
        "work":{
            "Heading":'Work Visa Requirements',
            "subHeadingOne":"",
           "dataOne": ["Employee Original Passport","Labour Approved Pre Approval","Company Trade License Copy","Photo","Sponsor Original ID","Sponsor Emirate ID Copy"]},
           "subHeadingTwo":"",
            "dataTwo":[],
            "subHeadingThree":"",
            "dataThree":[],
            "subHeadingFour":"",
            "dataFour":[],
            "talentHeading":"",
            "talentSubHeadingOne":"",
            "talentDataOne":[],
            "talentSubHeadingTwo":"",
            "talentDataOneTwo":[],
         
            "subHeadingFive":"",
            "dataFive":"",
            "subHeadingSix":"",
            "sixSubHeadingOne":"",
            "dataSixOne":[],
            "sixSubHeadingTwo":"",
            "dataSixTwo":[],
            
        
        "golden":{
            "Heading":'Golden Visa Requirements',
            "subHeadingOne":"Requirements of 10-year Golden Visa UAE for investors in public investment",
            "dataOne":["submission of a letter from an investment fund accredited in the UAE stating that the investor has a deposit of AED two million","submission of a valid commercial licence or industrial licence and a memorandum of association stating that the investor’s capital is not less than AED two million","submission of a letter from Federal Tax Authority stating that the investor pays the government no less than AED 250,000 (two hundred and fifty thousand) annually.","must own the invested capital completely, it must not be a loan ","provide proof of medical insurance for himself and his family (if any)."],
            "subHeadingTwo":"Requirements of 10-year Golden Visa UAE for job title",
            "dataTwo":["A copy of Passport","Residence Visa","personal photo with white background.","Bank statement showing salary more than 30,000 DHS in the past 6 months","NOC letter from the company to get Golden Visa.","Employment contract for the private sector or salary certificate for governmental or semi-governmental entities.","A copy of the university certificate attested from MOFA."],
            "subHeadingThree":"Requirements for 5-year Golden Visa UAE for Real Estate Investors",
            "dataThree":["Provision of a letter from the land department of the respective emirate stating that he owns one or more properties whose value is not less than 2 million dirhams ","Purchase of a property with a loan from specific local banks approved by the competent local entity."],
            "subHeadingFour":"Requirements for 5-year Golden Visa UAE for Entrepreneurs",
            "dataFour":["An auditor in the UAE stating that the project value is not less than 500,000 dirhams","The authorities in the emirate stating that the project is of a technical or future nature","An accredited business incubator in the UAE to establish the proposed activity in the country."],
            "talentHeading":"10-year Golden Visa UAE for Outstanding specialised talents",
            "talentSubHeadingOne":"Outstanding Talent Includes :",
            "talentDataOne":["Doctors","scientists","creative people of culture and art","specialists in scientific fields","doctoral degree holders and","specialists in the fields of engineering and science.","inventors","executives"],
            "talentSubHeadingTwo":"Subject Related to Engineering and science are below :",
            "talentDataOneTwo":["artificial intelligence","big data","computer engineering","software engineering","electronic engineering","electrical engineering","genetics and biotechnology engineering.","epidemiology and viruses"],
            "subHeadingFive":"Requirements of 5-year Golden Visa UAE for High School Students",
            "dataFive":"If you are a high school student and a national-level topper, (with a minimum grade of 95 per cent in public or private secondary school) you may be granted a Golden visa for 5 years without a sponsor, subject to submission of a recommendation letter from Ministry of Education (Emirates Schools Establishment).Duration of the Golden visa for students is 5 years and may be extended if he/she is enrolled in one of the majors/colleges in the country that requires a study period of more than five years.",
            "subHeadingSix":"Requirements of 10-year Golden Visa UAE for University Students",
            "sixSubHeadingOne":"If you are an outstanding university student, you may be granted a Golden visa for a period of 10 years without a sponsor, provided that:",
            "dataSixOne":["The university from which the applicant has completed the education must be rated either A or B class by Ministry of Education","A recommendation letter from the university or an accredited graduation certificate or an accredited academic record stating that the student’s cumulative GPA is not less than 3.5 for A class universities and 3.8 for B class universities is submitted.","it has not been more than 2 years since you graduated."],
            "sixSubHeadingTwo":"Outstanding students of foreign universities may be granted a Golden visa for a period of 10 years without a sponsor, provided that:",
            "dataSixTwo":["The university is rated among the best 100 universities global according to the rating system recognized by Ministry of Education","The student’s cumulative GPA is not less than 3.5","It has not been more than 2 years since you graduated","Graduation certificate is accredited by the Ministry of Education"],
            
        },
        "cancel":{
            "Heading":' Visa Cancelation Requirements',
            "subHeadingOne":"",
            "dataOne":["Applicants Passport","Applicants Original Emirates ID","Visa Copy"],
            "subHeadingTwo":"",
            "dataTwo":[],
            "subHeadingThree":"",
            "dataThree":[],
            "subHeadingFour":"",
            "dataFour":[],
            "talentHeading":"",
            "talentSubHeadingOne":"",
            "talentDataOne":[],
            "talentSubHeadingTwo":"",
            "talentDataOneTwo":[],
         
            "subHeadingFive":"",
            "dataFive":"",
            "subHeadingSix":"",
            "sixSubHeadingOne":"",
            "dataSixOne":[],
            "sixSubHeadingTwo":"",
            "dataSixTwo":[],
            
            
        }

    }
    
    const [goldBtn,setGold]=useState(true)
    const [familyBtn,setFamily]=useState(false)
    const [workBtn,setWork]=useState(false)
    const [cancelBtn,setCancel]=useState(false)
    const [VisaTextStyle,setVisaStyle]=useState('')
    
    const [changeStyle,setStyle]=useState('visa-types visa-text')
    const [visaText,setText]=useState(store.golden)
  
    useEffect(() => {
        scroll.scrollToTop();


    }, [])

    
    const hanldeGolden = () => {
        setGold(true)
        setCancel(false)
        setFamily(false)
        setWork(false)
        setText(store.golden)
        setStyle('visa-types visa-text visa-text-box-animate')
       
        setVisaStyle('fade-text-golden')
     
        
        
    }
    const hanldeFamily = () => {
        setGold(false)
        setCancel(false)
        setFamily(true)
        setWork(false)
         setText(store.family)
         setVisaStyle('fade-text-family') 
    }

    const hanldeWork = () => {
        setGold(false)
        setCancel(false)
        setFamily(false)
        setWork(true)
        setVisaStyle('fade-text-work') 
 
        setText(store.work)
    }
    const hanldeCancel = () => {
        setGold(false)
        setCancel(true)
        setFamily(false)
        setWork(false)
        setVisaStyle('fade-text-cancel') 
 
        setText(store.cancel)
    }

    return (
        <>
            <section id=' AmerServices' style={{ maxWidth:'100%' }}>
            
            <Home page={"Amer"}/>
            

            <div className='amer-box'  >
                <div className='second-box'>
                       <img src={Visa} height={"200px"} />
                </div>
                <div className='first-box '>
                        <h3 >Visa Services</h3>
                        <p className='head' > Your Gateway to Hassle-Free Visa Services in Dubai.</p>
                        <p>Whether you are visiting Dubai for tourism, business, employment, or family purposes, we cater to a wide range of visa types. We guide you in selecting the appropriate visa category based on your travel or immigration objectives.Below you can find the visa types and their requierments.</p>
                    
                        <div className='visa-types'>
                            <div className='sub-visa'>
                                <button className={goldBtn?'sub-visa-one visa-type-click':'visa-non-click sub-visa-one hover-style'} onClick={hanldeGolden} style={{marginLeft:'0px'}}>
                                     <div className='icon'>
                                        <img src={Golden} alt="" />        
                                    </div>
                                
                                    <h4>Golden Visa</h4>
                                </button>
                                <button className={familyBtn?'sub-visa-two visa-type-click':'sub-visa-two hover-style visa-non-click'} onClick={hanldeFamily}>
                                    <div className='icon'>
                                        <img src={Family} alt="" />        
                                    </div>                             
                                    <h4>Family Visa</h4>
                              
                                </button>
                            </div>
                            <div className='sub-visa'>
                                <button className={workBtn?'sub-visa-two visa-type-click':'sub-visa-two hover-style visa-non-click'} onClick={hanldeWork}>
                                    <div className='icon'>
                                    <img src={Job} alt="" />        
                                    </div>
                                    <h4>Work Permit</h4>
                                
                                </button>
                                <button className={cancelBtn?'sub-visa-two visa-type-click':'sub-visa-two hover-style visa-non-click'}onClick={hanldeCancel}>
                                <div className='icon'>
                                    <img src={Cancel} alt="" />        
                                    </div>
                                    <h4>Visa Cancelation</h4>
                                </button>
                            </div>
                            
                       
                        </div>
                        <div className={changeStyle}>
                            <h2 class={VisaTextStyle} >{visaText.Heading}</h2>
                            <h4 class={VisaTextStyle}>{visaText.subHeadingOne}</h4>
                           
                            <p class={VisaTextStyle}>
                                <ul>                          
                                {
                                    visaText.dataOne?.map((data,index)=>{
                                        return(
                                            <li key={index}>&#x2022; {data} </li>
                                        )
                                    })
                                }
                                </ul>
                            </p>
                            <h4 class={VisaTextStyle}>{visaText.subHeadingTwo}</h4>
                           
                            <p class={VisaTextStyle}>
                                <ul>                          
                                {
                                    visaText.dataTwo?.map((data,index)=>{
                                        return(
                                            <li key={index}>&#x2022; {data} </li>
                                        )
                                    })
                                }
                                </ul>
                            </p>
                            <h4 class={VisaTextStyle}>{visaText.subHeadingThree}</h4>
                           
                           <p class={VisaTextStyle}>
                               <ul>                          
                               {
                                   visaText.dataThree?.map((data,index)=>{
                                       return(
                                           <li key={index}>&#x2022; {data} </li>
                                       )
                                   })
                               }
                               </ul>
                           </p>
                            <h4 class={VisaTextStyle}>{visaText.subHeadingFour}</h4>
                           
                            <p class={VisaTextStyle}>
                                <ul>                          
                                {
                                    visaText.dataFour?.map((data,index)=>{
                                        return(
                                            <li key={index}>&#x2022; {data} </li>
                                        )
                                    })
                                }
                                </ul>
                            </p>
                            <h4 class={VisaTextStyle}>{visaText.talentHeading}</h4>
                            <p class={VisaTextStyle}> {visaText.talentSubHeadingOne}</p>
                            <div className='talent-box'>
                                
                                {
                            visaText.talentDataOne?.map((data,index)=>{
                                return(
                                    <p class={VisaTextStyle} key={index} >
                                        &#x2022; {data}
                                    </p>
                                )
                            })
                           }
                            </div>
                            <p class={VisaTextStyle}>  {visaText.talentSubHeadingTwo}</p>
                            <div className='talent-box'>
                                
                                {
                            visaText.talentDataOneTwo?.map((data,index)=>{
                                return(
                                    <p  key={index} class={VisaTextStyle}>
                                        &#x2022; {data}
                                    </p>
                                )
                            })
                           }
                            </div>

                            <h4 class={VisaTextStyle}>{visaText.subHeadingFive}</h4>
                            <p class={VisaTextStyle}> {visaText.dataFive}</p>
                           
                           <h4 class={VisaTextStyle}>{visaText.subHeadingSix}</h4>
                           <p>{visaText.sixSubHeadingOne}</p>
                           <p class={VisaTextStyle}>
                               <ul>                          
                               {
                                   visaText.dataSixOne?.map((data,index)=>{
                                       return(
                                           <li key={index}>&#x2022; {data} </li>
                                       )
                                   })
                               }
                               </ul>
                           </p>
                           <p >{visaText.sixSubHeadingTwo}</p>
                           
                           <p class={VisaTextStyle}>
                           <ul>                          
                               {
                                   visaText.dataSixTwo?.map((data,index)=>{
                                       return(
                                           <li key={index}>&#x2022; {data} </li>
                                       )
                                   })
                               }
                               </ul>
                           </p>
                           
                            {/* <h4 >For Entry Permit :</h4>
                            <p style={{backgroundColor:'red'}}>
                                <ul>
                                    <li>&#x2022;cdsd</li>
                                </ul>

                            </p>    
                            <h4 style={{backgroundColor:'rebeccapurple'}}>For Change Status :</h4>
                            <p>
                                <ul>
                                    <li>&#x2022;cdsd</li>
                                </ul>

                            </p> 
                            <h4 style={{backgroundColor:'rebeccapurple'}}>For Medical :</h4>
                            <p>
                                <ul>
                                    <li>&#x2022;cdsd</li>
                                </ul>

                            </p> 
                            <h4 style={{backgroundColor:'rebeccapurple'}}>For New Emirates ID  :</h4>
                            <p>
                                <ul>
                                    <li>&#x2022;cdsd</li>
                                </ul>

                            </p> 
                            <h4 style={{backgroundColor:'rebeccapurple'}}>For Insurance :</h4>
                            <p>
                                <ul>
                                    <li>&#x2022;cdsd</li>
                                </ul>

                            </p> 
                            <h4 style={{backgroundColor:'rebeccapurple'}}>For Visa Stamping :</h4>
                            <p>
                                <ul>
                                    <li>&#x2022;cdsd</li>
                                </ul>

                            </p>  */}
                        </div>
                </div>
                
            </div>
            <div className='amer-box' >
            <div className='second-box'>
                       <img src={Fine} height={"200px"} />
                </div>
                <div className='first-box red-layer'>
                        <h3 >Fine Waive</h3>
                        <p className='head' >Its better to take care of fine panalty before government does.</p>
                        <p>Overstaying more than the grace period provided by the UAE Government may lead to fines. However, if you have a valid reason due to unexpected situation beyond your control, there are some procedures set by the UAE Government to reconsider your condition.</p>

                </div>
                
            </div>
            <div className='amer-box' >
                <div className='first-box'>
                        <h3 >Change Status Services</h3>
                        <p className='head' >Want to change visa staus type? We are here to do it.</p>
                        <p>Visa status change typically refers to changing the type or category of a visa while staying in the UAE, such as switching from a tourist visa to a work visa or changing the sponsorship.</p>

                </div>
                <div className='second-box'>
                       <img src={Status} height={"200px"} />
                </div>
            </div>
            <div className='amer-box' >
                <div className='first-box green-layer'>
                        <h3 >Establishment card</h3>
                        <p className='head' >Want an establishment card for your company? We are here to do it for you.</p>
                        <p>An Establishment Card is issued to a company during its incorporation for the purpose of registering the company in the General Directorate of Residency and Foreigners Affairs (GDRFA) system in order to issue Investor, Partner or Employment Visas on behalf of the company.</p>

                </div>
                <div className='second-box'>
                       <img src={Establishment} height={"200px"} />
                </div>
            </div>


            
            </section>
        </>
    )
}

export default Amer