import React from 'react'
import QuickStop  from "../assets/img/quickStopLogo.png";
function Footer() {
  return (
    <>
        <footer id="footer">
<div class="container" style={{margin:'0px',width:'inherit',height:'inherit'}}>
    
    <div class="row" >
        
        <div class="col-md-6  " >
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.014162990736!2d55.31295600000001!3d25.270109!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43d5360c680b%3A0xbae330e4be09e182!2sAmer%20%26%20Dubai%20Economy%20-%20Quick%20Stop%20Gov%20Tr%20Center!5e0!3m2!1sen!2sae!4v1686217153310!5m2!1sen!2sae" width={"100%"} height={'550px'} style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div class="col-md-6" >
            <div className='logobox'>
                <img src={QuickStop} className=' logobox' alt="Quick Stop logo"/>
            </div>

            <div className='detailbox' >
            <span  ><i class=" fa fa-linkedin-square  " aria-hidden="true" ></i></span>
                <p class="">Quickstop22@gmail.com</p>
            </div>
            <div className='detailbox' >
            <span ><i class=" fa fa-mobile  " aria-hidden="true" ></i></span>
            <p>052 598 3088</p>
            </div>
            <div className='detailbox' >
            <span  ><i class=" fa fa-phone-square  " aria-hidden="true" ></i></span>
            <p class="tel">04 222 0013</p>
            </div>
            <div className='detailbox' >
            <span  ><i class=" fa fa-map-marker  " aria-hidden="true" ></i></span>
            <p class="tel">Amer & Dubai Economy - Quick Stop Gov Tr Center - Al Maktoum Hospital Road - Dubai</p>
            </div>
           
           
           
{/* 
            <ul class="social-list detailbox" style={{ justifyContent :'start'}}>
                <li><a href="https://www.facebook.com/amerquickstopcenter/"><i class="si social-facebook" aria-hidden="true"></i></a></li>
                <li><a  href="https://ae.linkedin.com/company/amer-quick-stop-govt-transaction-center"><i class=" fa fa-linkedin-square linkedIn " aria-hidden="true" ></i></a></li>
              
            </ul> */}

          

        </div>

    </div> 

</div> 

</footer>

    
    
    </>
  )
}

export default Footer