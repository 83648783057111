import React, { useEffect } from "react";
// Sections
import Home from "../components/Home";
import Services from "../components/Services";
import Portfolio from "../components/Portfolio";
import Contactus from "../components/Contactus";
import Carousel from "../components/Carousal";
var Scroll = require('react-scroll');
var scroller = Scroll.scroller;
var scroll = Scroll.animateScroll;



export default function Landing(props) {
  console.log(props.sec)
   
    useEffect(() => {
        const urls=window.location.href
        if (urls.includes('Contact'))
        {
          console.log('hello')
          scroller.scrollTo('contact', {
            duration: 1500,
            delay: 100,
            smooth: true,
         
            offset: 50,
          })
        }
        else if (urls.includes('why'))
        {
          scroller.scrollTo('reviews', {
            duration: 1500,
            delay: 100,
            smooth: true,
         
            offset: 50,
          })
        }
        else
        {
          scroll.scrollToTop();
        }
    
      
    }, [])
    
  

 
  return (
    <>
      <Home page={"home"}/>
      <Portfolio/>
      <Services />
      <Carousel/>
      <Contactus/>
    </>
  );
}


