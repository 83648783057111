import React, { useEffect } from 'react'
import Home from '../components/Home'
import Reserve from '../assets/img/visa/Dubai-Economy/reserve.png'
import Amendment from '../assets/img/visa/Dubai-Economy/amendment.png'
import Cancel from '../assets/img/visa/Dubai-Economy/cancel.png'
import Concern from '../assets/img/visa/Dubai-Economy/trade.png'
import Detail from '../assets/img/visa/Dubai-Economy/detail.png'
import Second from '../assets/img/visa/Dubai-Economy/hdpe-2.png'
import First from '../assets/img/visa/Dubai-Economy/plastic.png'
import Online from '../assets/img/visa/Dubai-Economy/online.png'
import Processing from '../assets/img/visa/Dubai-Economy/process.png'
import Print from '../assets/img/visa/Dubai-Economy/printer.png'
import Trade from '../assets/img/visa/Dubai-Economy/license.png'
import Licence from '../assets/img/visa/Dubai-Economy/licensing.png'
import Fine from '../assets/img/visa/Dubai-Economy/fine.png'
import FinePrint from '../assets/img/visa/Dubai-Economy/printer.png'
import Inspection from '../assets/img/visa/Dubai-Economy/check-mark.png'
import Request from '../assets/img/visa/Dubai-Economy/request.png'
import Memorendum from '../assets/img/visa/Dubai-Economy/documents.png'
import Approval from '../assets/img/visa/Dubai-Economy/stamp.png'


var Scroll = require('react-scroll');
var scroll = Scroll.animateScroll;


function DED() {
  useEffect(() => {
    scroll.scrollToTop();


}, [])
  const data=[{"icon":Reserve,"heading":"Trade Name Reservation"},
  {"icon":Licence,"heading":"License Renewal"},
  {"icon":Online,"heading":"Online initial Approval Issue and Renewal"},
  {"icon":Processing,"heading":"License Processing"},
  {"icon":Amendment,"heading":"License Amendment"},
  {"icon":Concern,"heading":"To Whom It may Concern Letter "},
  {"icon":Detail,"heading":"Contact Detail Change"},
  {"icon":Cancel,"heading":"License Cancellation"},
  {"icon":First,"heading":"Amendment to License - One Procedure"},
  {"icon":Second,"heading":"Amendment to License -More Than One Procedure"}, 
  {"icon":Trade,"heading":"Trade Permit Issue,Cancelation,Amendment,Renewal"}]


  const data2=[{"icon":Request,"heading":"Fine Settlement Request"},
  {"icon":Fine,"heading":"Fine Objection Request"},
  {"icon":Inspection,"heading":"Inspection Report Printing"},
  {"icon":FinePrint,"heading":"Fine Payment Voucher Printing"},
  {"icon":Memorendum,"heading":"Issuance Of Memorendum Of Association"},
  {"icon":Approval,"heading":"Issuance Of Initial Approval"},
]
  return (
    <>
      <section id='DED'>
        <Home page={'DED'}></Home>
        <div className='DED-container'>
            <h3>ALL COMMERCIAL REGISTRATION SERVICES</h3>
            <div className='DED-sub-container'>
              {data?.map((dat,index)=>{
                return(
                  <div className='DED-card' key={index}>
                      <img src={dat.icon} alt={dat.heading}/>
                      
                      <h4>{dat.heading}</h4>
                  </div>
                )
              })}
            </div>
        </div>
        <div className='DED-container' style={{backgroundColor:'aliceblue'}}>
            <h3> COMMERCIAL CONTROL AND CONSUMER PROTECTION</h3>
            <div className='DED-sub-container'>
              {data2?.map((dat,index)=>{
                return(
                  <div className='DED-card' key={index}>
                      <img src={dat.icon} alt={dat.heading}/>
                      
                      <h4>{dat.heading}</h4>
                  </div>
                )
              })}
            </div>
        </div>
      </section>

    </>
  )
}

export default DED