import React, { useEffect } from 'react'
import Home from '../components/Home'
import Application from '../assets/img/visa/EHS/application.svg'
var Scroll = require('react-scroll');
var scroll = Scroll.animateScroll;
function EHS() {
    useEffect(() => {
        scroll.scrollToTop();


    }, [])

  return (
    <>
        <section id='EHS'>
            <Home page={'EHS'}/>
            <div className='amer-box'  >
                <div className=' sub-box-one newID-sub-box-one '  >
                
                    <img src={Application} alt="Medical Fitness Test Image" style={{maxHeight:'750px'}} />
                </div>
                <div className=' sub-box-two newID-sub-box-two ' style={{backgroundColor: '#e1c398'}} >
                    <h3>Medical fitness test Application</h3>
                   
                    <p>We offer expert typing services for medical fitness test application forms for your Residency.
Our typists are well-versed in the specific requirements and guidelines for medical fitness test applications.</p>
                </div>
                
            </div>
        </section>
    
    </>
  )
}

export default EHS